/*
NEVER GIVE HEIGHT
ONLY ROOT FLEX BOX SHOULD HAVE HEIGHT
(CHILDREN SHOULD NEVER HAVE MANUALLY. --- FOR BUG-FIX SAFARI )
*/
.flexContainerColumn,
.flexContainerRow {
    display: -webkit-flex; /* Safari */
    display: -moz-box;
    display: -ms-flexbox;
    -moz-display: flex;
    -ms-display: flex;
    display: flex;

    -webkit-align-items: stretch;
    align-items: stretch; /* align items in Cross Axis */

    -webkit-align-content: stretch; /* Extra space in Cross Axis */
    align-content: stretch; /* Extra space in Cross Axis */

    -webkit-justify-content: flex-start;
    justify-content: flex-start;

    -webkit-flex-basis: 100%;
    flex-basis: 100%;

    width: 100%;
    height: 100%;

    position: relative;

    background: transparent;
}
.flexContainerColumn > * {
    width: 100%;
}
.flexContainerRow > * {
    height: 100%;
}

.flexContainerRow > .flexItem11{
    overflow-x: hidden;
}
.flexContainerColumn {
    -webkit-flex-flow: column;
    flex-flow: column;

    -webkit-flex-direction: column;
    flex-direction: column;

    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
}
.flexContainerColumn > .flexItem11 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
}
.flexContainerRow {
    -webkit-flex-flow: row;
    flex-flow: row;

    -webkit-flex-direction: row;
    flex-direction: row;

    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;

}

.flexContainerRow > .flexItem11 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
}
.flexContainerWrap{
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap; /* Safari 6.1+ */
}
.flexContainerWrap.flexContainerRow > * {
    height: auto;
}
.flexContainerWrap.flexContainerColumn > * {
    width: auto;
}


.flexItem11 ,
.flexItem00 ,
.flexItem10 ,
.flexItem01 {
    /* THE CHILDREN SHOULD NOT BE FLEX BOX */
    /*display: -webkit-flex; !* Safari *!*/
    /*display: -moz-box;*/
    /*display: -ms-flexbox;*/
    /*-moz-display: flex;*/
    /*-ms-display: flex;*/
    /*display: flex;*/
    display: block;

    /* IT SHOULD NOT BE APPLIED BECAUSE OTHER SELECTOR WILL DO*/
    /*width: auto;*/
    /*height: auto;*/

    position: relative;
    background: transparent;
    overflow: visible;
}
.flexItem11 {
    display: flex;

    -webkit-flex-grow: 1;
    -webkit-flex-shrink: 1;
    -webkit-flex-basis: auto;
    -moz-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    overflow: visible;
}
.flexItem00 {
    -webkit-flex-grow: 0;
    -webkit-flex-shrink: 0;
    -webkit-flex-basis: auto;
    -moz-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
}
.flexItem10 {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -webkit-flex-shrink: 0;
    -webkit-flex-basis: auto;
    -moz-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
}
.flexItem01 {
    -webkit-flex-grow: 0;
    -webkit-flex-shrink: 1;
    -webkit-flex-basis: auto;
    -moz-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
}
.flexItemAlignCenterCenter {
    display: -webkit-flex; /* Safari */
    display: -moz-box;
    display: -ms-flexbox;
    -moz-display: flex;
    -ms-display: flex;
    display: flex;

    -webkit-justify-content: center;
    justify-content: center;

    -webkit-align-items: center;
    align-items: center;
}
.flexItemAlignCenterStart{
    display: -webkit-flex; /* Safari */
    display: -moz-box;
    display: -ms-flexbox;
    -moz-display: flex;
    -ms-display: flex;
    display: flex;

    -webkit-justify-content: center;
    justify-content: center;

    -webkit-align-items: flex-start;
    align-items: flex-start;
}
.flexItemAlignStartCenter{
    display: -webkit-flex; /* Safari */
    display: -moz-box;
    display: -ms-flexbox;
    -moz-display: flex;
    -ms-display: flex;
    display: flex;

    -webkit-justify-content: flex-start;
    justify-content: flex-start;

    -webkit-align-items: center;
    align-items: center;
}
.flexItemAlignStartStart{
    display: -webkit-flex; /* Safari */
    display: -moz-box;
    display: -ms-flexbox;
    -moz-display: flex;
    -ms-display: flex;
    display: flex;

    -webkit-justify-content: flex-start;
    justify-content: flex-start;

    -webkit-align-items: flex-start;
    align-items: flex-start;
}
.flexItemAlignStartEnd{
    display: -webkit-flex; /* Safari */
    display: -moz-box;
    display: -ms-flexbox;
    -moz-display: flex;
    -ms-display: flex;
    display: flex;

    -webkit-justify-content: flex-start;
    justify-content: flex-start;

    -webkit-align-items: flex-end;
    align-items: flex-end;
}
.flexItemAlignEndCenter{
    display: -webkit-flex; /* Safari */
    display: -moz-box;
    display: -ms-flexbox;
    -moz-display: flex;
    -ms-display: flex;
    display: flex;

    -webkit-justify-content: flex-end;
    justify-content: flex-end;

    -webkit-align-items: center;
    align-items: center;

}
.flexItemAlignEndEnd{
    display: -webkit-flex; /* Safari */
    display: -moz-box;
    display: -ms-flexbox;
    -moz-display: flex;
    -ms-display: flex;
    display: flex;

    -webkit-justify-content: flex-end;
    justify-content: flex-end;

    -webkit-align-items: flex-end;
    align-items: flex-end;

}

.flexContainerRow.bothScroll,
.flexContainerColumn.bothScroll,
.flexItem11.bothScroll,
.bothScroll{
    overflow-x: scroll;
    overflow-y: scroll;
}
.flexContainerColumn.verticalScroll,
.flexItem11.verticalScroll,
.verticalScroll{
    overflow-x: hidden;
    overflow-y: scroll;
}
.flexContainerRow.horizontalScroll,
.flexItem11.horizontalScroll,
.horizontalScroll{
    overflow-x: scroll;
    overflow-y: hidden;
}
.flexContainerColumn.verticalScrollAuto,
.flexItem11.verticalScrollAuto,
.verticalScrollAuto{
    overflow-x: hidden;
    overflow-y: auto;
}
.flexContainerRow.horizontalScrollAuto,
.flexItem11.horizontalScrollAuto,
.horizontalScrollAuto{
    overflow-x: scroll;
    overflow-y: auto;
}

.neverOverflow{
    overflow-x: hidden;
    overflow-y: hidden;
}
.scroll{
   overflow: scroll;
}

