.library.tabBackground {
    background-color: #1e2224;
    /*background-image: url("https://www.transparenttextures.com/patterns/shattered-dark.png");*/
    background-image: "url(/assets/img/patterns/shattered-dark.png)";
}
.library.headerBackground {
    background-color: #2e3031;
    /*background-image: url("https://www.transparenttextures.com/patterns/black-orchid.png");*/
    background-image: "url(/assets/img/patterns/black-orchid.png)";
}
.library.headerBackgroundShadow {
    position: relative;
    z-index: 10;
    box-shadow: 4px 2px 4px rgba(0,0,0,.2);
}
.library.mainContentBackground {
    background-color: #505050;
    /*background-image: url("https://www.transparenttextures.com/patterns/dark-matter.png");*/
    background-image: "url(/assets/img/patterns/dark-matter.png)";
}
.library.footerBackgroundShadow {
    box-shadow: -4px -2px 4px rgba(0,0,0,.2);
}

.library .libraryHeadBreadcrumb{
    color: #d8d7d7;
    font-size: 16px;
    height: 100%;
    padding-left: 10px;
    display: inline-flex;
    align-items: center;
}



/* SQUARED THREE */
.squaredThree {
    width: 18px;
    margin-right: 5px;
    position: relative;
    display: inline-block;
}
.squaredThree input[type=checkbox] {
    visibility: hidden;
    width: 0px;
    height: 0px;
}

.squaredThree label {
    cursor: pointer;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    border-radius: 4px;

    -webkit-box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,.4);
    -moz-box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,.4);
    box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,.4);

    background: -webkit-linear-gradient(top, #222 0%, #45484d 100%);
    background: -moz-linear-gradient(top, #222 0%, #45484d 100%);
    background: -o-linear-gradient(top, #222 0%, #45484d 100%);
    background: -ms-linear-gradient(top, #222 0%, #45484d 100%);
    background: linear-gradient(top, #222 0%, #45484d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#222', endColorstr='#45484d',GradientType=0 );
}

.squaredThree label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 4px;
    left: 4px;
    border: 3px solid #fcfff4;
    border-top: none;
    border-right: none;

    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.squaredThree label:hover::after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    opacity: 0.3;
}

.squaredThree input[type=checkbox]:checked + label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}


/*http://codepen.io/thebabydino/pen/rzIJB*/
.pictureBoxInSizeWithOriginalRatio.container {
    display: inline-block;
    position: relative;
    border: solid .25em deeppink;
    width: 8em; height: 10em;
}
.pictureBoxInSizeWithOriginalRatio.container .pictureBoxInSizeWithOriginalRatio.img {
    position: absolute;
    top: 50%; left: 50%;
    width: auto; height: auto;
    max-width: 100%; max-height: 100%;
    transform: translate(-50%, -50%);
}

.DimLoaderComponent.loader {
    display: inline-block;
    /*border: 16px solid #f3f3f3; !* Light grey *!*/
    border: 12px solid #a333c8;
    border-top: 12px solid #3498db; /* Blue */
    border-radius: 50%;
    padding: 11px;
    animation: DimLoaderComponentSpin 2s linear infinite;
}

@keyframes DimLoaderComponentSpin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}