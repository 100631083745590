.publishSubscriber .selectionThumbnail.borderBox{
    -webkit-box-shadow: 7px 7px 5px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow:    7px 7px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow:         7px 7px 5px 0px rgba(0, 0, 0, 0.12);
}

.publishSubscriber .selectionThumbnail.backgroundWrapper{
    background: -moz-linear-gradient(270deg, rgba(255,255,255, 0) calc(100% - 40px), rgba(0,0,0,0.9) 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(75%, rgba(255,255,255,1)), color-stop(100%, rgba(0,0,0,0.9))); /* safari4+,chrome */
    background: -webkit-linear-gradient(270deg, rgba(255,255,255, 0) calc(100% - 40px), rgba(0,0,0,0.9) 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(270deg, rgba(255,255,255, 0) calc(100% - 40px), rgba(0,0,0,0.9) 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(270deg, rgba(255,255,255, 0) calc(100% - 40px), rgba(0,0,0,0.9) 100%); /* ie10+ */
    background: linear-gradient(180deg, rgba(255,255,255, 0) calc(100% - 40px), rgba(0,0,0,0.9) 100%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* ie6-9 */

    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;

    cursor: pointer;

    z-index: 100;
}

.publishSubscriber .selectionThumbnail.backgroundWrapper:hover{
    background-color:  rgba(0,0,0, 0.6);
    color: white;
}
.publishSubscriber .selectionThumbnail.hoverBox{
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;

    z-index: 250;
}

.publishSubscriber .selectionThumbnail.noticeTop{
    position: absolute;
    top: 0%;
    left: -35px;
    right:0%;
    padding: 12px;
    color: white;
    z-index: 200;
}
.publishSubscriber .selectionThumbnail.noticeBottom{
    position: absolute;
    bottom: 0%;
    left:0%;
    right:0%;
    padding: 12px;
    color: white;
    z-index: 200;
}