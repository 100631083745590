.PublishReleaseCollectionBrowserBoxTheme .selectionThumbnail.borderBox{
    -webkit-box-shadow: 7px 7px 5px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow:    7px 7px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow:         7px 7px 5px 0px rgba(0, 0, 0, 0.12);
}

.PublishReleaseCollectionBrowserBoxTheme .selectionThumbnail.backgroundWrapper{
    background: -moz-linear-gradient(270deg, rgba(255,255,255, 0) calc(100% - 40px), rgba(0,0,0,0.9) 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(75%, rgba(255,255,255,1)), color-stop(100%, rgba(0,0,0,0.9))); /* safari4+,chrome */
    background: -webkit-linear-gradient(270deg, rgba(255,255,255, 0) calc(100% - 40px), rgba(0,0,0,0.9) 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(270deg, rgba(255,255,255, 0) calc(100% - 40px), rgba(0,0,0,0.9) 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(270deg, rgba(255,255,255, 0) calc(100% - 40px), rgba(0,0,0,0.9) 100%); /* ie10+ */
    background: linear-gradient(180deg, rgba(255,255,255, 0) calc(100% - 40px), rgba(0,0,0,0.9) 100%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* ie6-9 */

    /*position: absolute;*/
    /*top: 0%;*/
    /*bottom: 0%;*/
    /*left: 0%;*/
    /*right: 0%;*/
}


.PublishReleaseCollectionBrowserBoxTheme .selectionThumbnail.caption.thumbnail{
    color: black;
    background-color: #FFF;
}
.PublishReleaseCollectionBrowserBoxTheme .selectionThumbnail.caption.thumbnail .detail{
    color: #CCCCCC;
    font-size: 0.8em;
}

.PublishReleaseCollectionBrowserBoxTheme .selectionThumbnail.caption.view{
    padding: 10px;
    /*color: black;*/
    /*background-color: #f6f6f6;*/
}
